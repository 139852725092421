import { useEffect } from 'react';

import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import commonApi from '@fo-apis/common/commonApi';

const SiteInformationProvider = () => {
  const { setSystemInformation, getSystemInformationByKey } = useSiteInfo();

  useEffect(() => {
    const handleSiteInformation = async () => {
      if (!getSystemInformationByKey('siteName')) {
        const { result } = await commonApi.getSiteInformation();
        if (result.siteDesc) {
          const htmlContent = document.querySelector('meta[name="description"]'); // <content> 태그 찾기
          htmlContent.setAttribute('content', result.siteDesc);
        }
        const htmlTitle = document.querySelector('title'); // <title> 태그 찾기

        htmlTitle.innerText = (result && result.isMultimall) ?  result.siteName : result.siteName + ' - 건강함에 새로움을 더하다.' ;	// <title> 태그에 변수 title값을 넣음
        setSystemInformation(result);
      }
    };
    handleSiteInformation();
  }, [getSystemInformationByKey, setSystemInformation]);
};

export default SiteInformationProvider;
