export const color = {
  primary: '#FF5624',
  secondary: '#727272',
  third: '#141415',
  disabled: '#d3d3d3',
  sub_darkgray: '#3C3C3C',
  sub: '#FF8718',
  fourth: '#23A0B1',
  stroke: '#F22214',
  hover: {
    primary: '#E65429',
    secondary: '#505050',
    third: '#FF5624',
    default: '#eaeaea',
  },
  text: {
    basic: '#141415',
    basicTab: '#FF5624',
    sub: '#5a5a5a',
    guide: '#727272',
    category: '#9c52ff',
    price: '#c551c0',
    white: '#ffffff',
    placeholder: '#a3a3a3',
    disabled: '#727272',
    error: '#f22214',
    event: '#7e9ae3',
    normal: '#7a7a7a',
    mileage: '#fbff94',
    attendance: '#FF5624',
    selected: '#FF5624',
    food: '#fe6f1f',
    best: '#0dc335',
    policy: '#FF5624',
    newPlaceholder: '#595959',
  },
  point: {
    basic: '#FF5624',
    negative: '#f22214',
  },
  line: {
    basic: '#eaeaea',
    background: '#dfdfdf',
    emphasis: '#141415',
    category: '#9c52ff',
    price: '#c551c0',
    disabled: '#d3d3d3',
    error: '#f22214',
    selected: '#7a7a7a',
    sub: '#bdbdbd',
    line02: '#d3d3d3',
  },
  background: {
    basic: '#f7f7f7',
    sub: '#FFEFEA',
    sub1: '#FFEFEA',
    sub2: '#f3f3f3',
    white: '#ffffff',
    disabled: '#d3d3d3',
    gradient: '#FF8718',
    coupon: {
      good: '#efedff',
      cart: '#fff1ed',
      delivery: '#e2f8ff',
      default: '#5a5a5a',
    },
    error: '#ffa2a2',
  },
  icon: {
    default: '#919191',
    current: '#FF5624',
    normal: '#6a6a6a',
    badge: '#27b7db',
    benefit: '#e0dcff',
  },
};

export const font = {
  size: {
    tiny: '10px',
    calendar: '11px',
    error: '12px',
    small: '13px',
    basic: '14px',
    table: '15px',
    label: '16px',
    menu: '17px',
    title: '18px',
    popup: '20px',
    medium: '21px',
    percent: '22px',
    banner: '24px',
    discount: '26px',
    large: '28px',
    hero: '48px',
  },
  weight: {
    demiLight: 300,
    medium: 500,
    medium2: 400,
    bold: 600,
  },
  lineHeight: {
    demiLight: '160%',
    medium: '140%',
    bold: '100%',
  },
  'line-spacing': {},
};

export const theme = {
  color,
  font,
};

export default theme;
