import React from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';

import * as foTheme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox, TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

import EventEndDimPopupWrap from '@fo-components/event/EventEndDimPopupWrap';

export const EventCard = ({
  name,
  subName,
  imgPath,
  startDate,
  endDate,
  state,
  // handleEventCardClick,
  dateHidden,
  cardProps,
  imageProps,
  eventNumber,
}) => {
  return (
    <a href={'/event/' + eventNumber} aria-label={name}>
      <FlexBoxColumn
        width={cardProps.width}
        height={cardProps.height}
        border-radius={'8px'}
        // onClick={handleEventCardClick}
        style={{ cursor: 'pointer' }}
        data-ds-contents={eventNumber}
      >
        <FlexBox>
          {'end' === state && (
            <EventEndDimPopupWrap
              text={`종료된 
          이벤트입니다.`}
            />
          )}
          <StyledImg src={imgPath} radius={'8px'} width={imageProps.width} height={imageProps.height} alt={name} />
        </FlexBox>
        <Spacing top={14} />
        <FlexBoxColumn width={'100%'} height={'44px'} justify-content={'flex-start'} align-items={'center'}>
          <TextBoxAlignLeft
            width={'100%'}
            weight={500}
            size={16}
            color={foTheme.color.text.basic}
            style={{
              lineHeight: '140%',
            }}
          >
            {name}
          </TextBoxAlignLeft>
          <TextBoxAlignLeft
            width={'100%'}
            weight={500}
            size={16}
            color={foTheme.color.text.basic}
            style={{
              lineHeight: '140%',
            }}
          >
            {subName}
          </TextBoxAlignLeft>
        </FlexBoxColumn>
        <Spacing top={10} />
        {!dateHidden && (
          <TextBox size={13} width={'100%'} color={foTheme.color.text.normal} weight={350}>
            {`${format(new Date(startDate), 'yyyy. MM. dd')} ~ ${format(new Date(endDate), 'yyyy. MM. dd')}`}
          </TextBox>
        )}
      </FlexBoxColumn>
    </a>
  );
};

EventCard.propTypes = {
  name: PropTypes.string,
  subName: PropTypes.string,
  imgPath: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  state: PropTypes.string,
  handleEventCardClick: PropTypes.func,
  dateHidden: PropTypes.bool,
  cardProps: PropTypes.object,
  imageProps: PropTypes.object,
  eventNumber: PropTypes.string,
};

EventCard.defaultProps = {
  dateHidden: false,
  cardProps: { width: '240px', height: '230px' },
  imageProps: { width: '240px', height: '140px' },
};
